var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "popup_wrap", staticStyle: { width: "750px" } },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [_vm._v(" close ")]
      ),
      _c("div", { staticClass: "popup_cont" }, [
        _c("h1", { staticClass: "page_title" }, [
          _vm._v(_vm._s(_vm.$t("msg.NEWB020P030.003"))),
        ]),
        _c(
          "div",
          { staticClass: "content_box" },
          [
            _vm.customComponent
              ? _c(_vm.customComponent, {
                  ref: "dextupload",
                  tag: "component",
                  attrs: {
                    id: "dextupload1",
                    width: "100%",
                    height: "200px",
                    category: _vm.isCategory,
                    "file-info": _vm.uploadFileList,
                    "category-info": _vm.msdsList,
                  },
                })
              : _vm._e(),
          ],
          1
        ),
        _c("div", { staticClass: "mt10 text_center" }, [
          _c(
            "a",
            {
              staticClass: "button blue lg",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.insertFile()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("msg.NEWB020P030.004")))]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }